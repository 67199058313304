import { useContext, useEffect } from 'react';
import Cookies from 'universal-cookie';
import getConfig from 'next/config';
import Logger from '@lmig/dotcom-utilities-helpers/logger';
import { ContextApp } from '@lmig/dotcom-aspect-components/ContextApp';
import { EntityContext } from '@lmig/dotcom-aspect-comparion-components/EntityContext';

const logger = new Logger('client:Utility:WriteStateCookie');
const { publicRuntimeConfig: { cookieName } } = getConfig();
const cookieOptions = { path: '/' };

const clearCookie = () => {
  const cookies = new Cookies({}, cookieOptions);
  cookies.remove(cookieName, cookieOptions);
};

const buildCookie = (options = {}) => {
  const { agent, cap, host, isNoIndex } = options;
  const cookieObj = {};

  if (agent?.nNumber) {
    cookieObj.nNumber = agent.nNumber;
  }

  if (cap?.partnerId) {
    cookieObj.partnerId = cap.partnerId;

    if (agent?.nNumber) {
      const agentInCap = cap.agents?.find(capAgent => capAgent.nNumber === agent.nNumber);

      if (agentInCap) {
        cookieObj.nNumber = agent.nNumber;
      } else {
        delete cookieObj.nNumber;
      }
    }
  }

  if (host) {
    cookieObj.host = host;
  }

  if (isNoIndex) {
    cookieObj.noIndex = true;
  }

  return Object.keys(cookieObj).length >= 1 ? JSON.stringify(cookieObj) : null;
};

const WriteStateCookie = () => {
  const { entities: { cap, agent }, isNoIndex } = useContext(EntityContext);
  const { host } = useContext(ContextApp);

  useEffect(() => {
    try {
      const cookies = new Cookies({}, cookieOptions);
      let agentToUse = {};
      let capToUse = {};

      agentToUse = agent?.nNumber ? agent : {};
      capToUse = cap?.partnerId ? cap : {};

      const cookieValue = buildCookie({ agent: agentToUse, cap: capToUse, host, isNoIndex });

      if (cookieValue) {
        logger.debug(`Writing ${cookieName} cookie: ${cookieValue}`);

        cookies.set(cookieName, cookieValue, cookieOptions);
      }
    } catch (e) {
      logger.error(`Error writing state cookie: ${e.message}`);
    }
  }, [agent, cap, host, isNoIndex]);

  return null;
};

export default WriteStateCookie;

export { clearCookie };
