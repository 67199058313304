/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";
import App from "next/app";
import getConfig from 'next/config';
import Logger from '@lmig/dotcom-utilities-helpers/logger';
import { PageContextProvider } from "@lmig/dotcom-aspect-components/ContextPage";
import { AppContextProvider } from '@lmig/dotcom-aspect-components/ContextApp';
import { EntityContextProvider } from '@lmig/dotcom-aspect-comparion-components/EntityContext';
import { ParameterContextProvider } from "@lmig/dotcom-aspect-components/ContextParameter";
import WriteStateCookie from '@lmig/dotcom-aspect-comparion-components/SystemUtils/WriteStateCookie';
import GetUserLocation from '@lmig/dotcom-aspect-components/SystemUtils/GetUserLocation';
import fetchRetry from "@lmig/dotcom-aspect-comparion-helpers/fetchRetry";
import { initDatadogRum } from "../server/helpers/datadog";

const logger = new Logger('client:app');

const {
  publicRuntimeConfig: { datadog },
  serverRuntimeConfig: { originDomain },
} = getConfig();

initDatadogRum(datadog);
export default class ComparionApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let initialPageProps = {};
    const contextApp = {};
    const contextEntity = {};

    if (Component.getInitialProps) {
      initialPageProps = await Component.getInitialProps(ctx);
    }

    if (ctx.req?.headers?.host) {
      const protocol = ctx.req.headers.host.includes("localhost") ? "http:" : "https:";
      contextApp.host = `${protocol}//${ctx.req.headers.host}`;
    }

    let pageOptions = {
      useSession: true,
    };

    if (ctx.query?.currentPage) {
      pageOptions = JSON.parse(ctx.query.currentPage);
      pageOptions.useSession = 'useSession' in pageOptions ? pageOptions.useSession : true;
    }

    if (typeof window === 'undefined') {
      // don't put cap from cookie in session if rendering cap page
      if (!ctx.query.isCap) {
        let capResponse;

        // don't check for alias session if rendering agent page
        if (!ctx.query.isAgent && ctx.req?.query?.alias) {
          capResponse = await fetchRetry(`${originDomain}/api/v1/capSession?alias=${ctx.req.query.alias}`).catch(e => { logger.error(e); });
        } else if (pageOptions.useSession && ctx.req?.comparionContextValues?.partnerId) {
          capResponse = await fetchRetry(`${originDomain}/api/v1/capSession?partnerId=${ctx.req.comparionContextValues.partnerId}`).catch(e => { logger.error(e); });
        }

        if (capResponse?.status === 200) {
          const cap = await capResponse.json();
          contextEntity.cap = cap;
        }
      }

      // don't put agent from cookie in session if rendering agent page
      if (!ctx.query.isAgent) {
        let agentResponse;

        // don't check for alias session if rendering cap page
        if (!ctx.query.isCap) {
          if (ctx.req?.query?.alias) {
            agentResponse = await fetchRetry(`${originDomain}/api/v1/agentSession?alias=${ctx.req.query.alias}`).catch(e => { logger.error(e); });
          } else if (pageOptions.useSession && ctx.req?.comparionContextValues?.nNumber) {
            agentResponse = await fetchRetry(`${originDomain}/api/v1/agentSession?nNumber=${ctx.req.comparionContextValues.nNumber}`).catch(e => { logger.error(e); });
          }

          if (ctx.req?.query?.agent) {
            // only put agent in session if agent is assocaite with the cap
            if (contextEntity.cap && contextEntity.cap?.agents) {
              const agent = contextEntity.cap.agents.find(capAgent => capAgent.alias === ctx.req.query.agent);

              if (agent) {
                agentResponse = await fetchRetry(`${originDomain}/api/v1/agentSession?alias=${ctx.req.query.agent}`).catch(e => { logger.error(e); });
              }
            }
          }
        }

        if (agentResponse?.status === 200) {
          const agent = await agentResponse.json();
          contextEntity.agent = agent;
        }
      }
    }

    // TODO: removing this as it keeps the page from being indexed when it shouldn't be
    // contextEntity.isNoIndex = !!Object.keys(ctx.req?.query || {}).length;

    return { pageProps: initialPageProps, contextApp, contextEntity };
  }

  render() {
    const { Component, pageProps, contextApp, contextEntity } = this.props;

    return (
      <AppContextProvider {...contextApp}>
        <EntityContextProvider {...contextEntity}>
          <ParameterContextProvider>
            <PageContextProvider {...pageProps}>
              <Component {...pageProps} />
              <WriteStateCookie />
              <GetUserLocation html />
            </PageContextProvider>
          </ParameterContextProvider>
        </EntityContextProvider>
      </AppContextProvider>
    );
  }
}

ComparionApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};
