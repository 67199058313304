/* eslint-disable prefer-promise-reject-errors */
const fetch = require('@lmig/dotcom-aspect-helpers/isoApiCall');
const Logger = require("@lmig/dotcom-utilities-helpers/logger");

const logger = new Logger('client:helpers:fetchRetry');

class FetchError {
  constructor(res) {
    this.res = res;
    this.name = 'notok';
  }
}

const recursiveFetch = async ({ url, options, retryAttempts = 0, lastError }) => {
  if (retryAttempts >= options.retries) {
    logger.warn(`recursiveFetch: maximum retries reached for: ${url}`);
    let message = `${url} is unavailable`;

    try {
      const json = await lastError.json();
      message += ` with error: ${json.error || JSON.stringify(json)}`;
    } catch (e) {
      logger.warn(`no json error response from ${url}`);
    }

    return Promise.reject({ message, response: lastError });
  }

  return fetch(url, options)
    .then(res => {
      if (!res.ok) {
        logger.warn(`received ${res.status} ${res.statusText} for url: ${url}`);
        throw new FetchError(res);
      }

      return res;
    })
    .catch(error => {
      let { message } = error;

      if (error?.res?.status === 400) {
        logger.warn(`${url} returned a 400, skipping retry`);

        return recursiveFetch({ url, options, retryAttempts: options.retries, lastError: error.res });
      }

      if (error.name === "notok") {
        message = `recursiveFetch: not ok response code for ${url} : trying again. attempt: ${retryAttempts + 1}`;
      } else {
        message += ` - trying again. attempt: ${retryAttempts + 1}`;
      }

      logger.warn(message);

      // eslint-disable-next-line no-plusplus, no-param-reassign
      return recursiveFetch({ url, options, retryAttempts: ++retryAttempts, lastError: error.res });
    });
};

module.exports = (url, options) => recursiveFetch({ url, options: { timeout: 10000, retries: 3, ...options } });
